import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import AnimationWrapper from "../components/animationWrapper"
import Carousel from "../components/carousel"
import ContactSection from "../components/contactSection"
import Container from "../components/container"
import CtaButtonGrid from "../components/ctaButtonGrid"
import IconSection from "../components/iconSection"
import TextSection from "../components/textSection"
import TileSection from "../components/tileSection"
import Title from "../components/title"
import Slider from "../components/swiper"
import Layout from "../components/layout"


import "./index.css"

const IndexPage = ( {data, location} ) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <section id="home-landing" className="page-section">
        <AnimationWrapper>
          <Slider data={data.page.frontmatter.slides} />
        </AnimationWrapper>
      </section>

      {data.page.frontmatter.text_section_main.active &&
        <section id="homepage-main-text-section" className="page-section">
          <Container>
            <AnimationWrapper>
              <TextSection className="description-medium home-main" data={data.page.frontmatter.text_section_main} headingTag='h1' />
            </AnimationWrapper>
          </Container>
        </section>
      }

      <section id="homepage-tiles-section" className="page-section">
        <Container>
          <AnimationWrapper animationType="fadeIn">
            <TileSection data={data.tiles} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      <section id="homepage-keramzyt-section" className="page-section">
        <Container>
          <AnimationWrapper animationType="fadeIn">
            <Title title={data.page.frontmatter.icons_section_title} />
            <IconSection data={data.page.frontmatter} image={data.iconsKeramzytImg} location="homepage-keramzyt" />
          </AnimationWrapper>
        </Container>
      </section>

      <section id="realizacje-carousel-section" className="page-section">
        <Carousel data={data.realizacjeCarousel.frontmatter.carousel_gallery} location="realizacje" />
      </section>

      <section id="homepage-cta-button-section" className="page-section">
        <Container>
          <AnimationWrapper animationType="fadeIn">
            <Title title="Masz pytanie? Wybierz temat i napisz do nas!" />
            <CtaButtonGrid />
          </AnimationWrapper>
        </Container>
      </section>

      {data.page.frontmatter.text_section_bottom.active &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_section_bottom} />
          </AnimationWrapper>
        </Container>
      }

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexPage {
    page: mdx(frontmatter: { slug: { eq: "index" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slides{
          title
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
          button_text
          button_style
          button_url
          text
        }
        text_section_main{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
        icons_section_title
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
        text_section_bottom{
          active
          title
          title_visible
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          align
          text
          button_text
          button_url
          button_style
        }
      }
    }
    tiles: allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {internal: {contentFilePath: {regex: "/(tiles/homepage/)/"}}}
    ){
      nodes {
        frontmatter {
          title
          date(formatString: "MMMM D, YYYY")
          title_color
          title_bg_color
          type
          url
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit:COVER}
                webpOptions: {quality:95}
              )
            }
          }
        }
        id
      }
    }
    iconsKeramzytImg: file(
      relativePath: {eq: "keramzyt-torba.png"}
    ){
      id
      publicURL
      childImageSharp {
        gatsbyImageData(
          transformOptions: {fit: COVER},
          webpOptions: {quality: 95}
        )
      }
      relativePath
    }
    realizacjeCarousel: mdx(
      frontmatter: {slug: {eq: "realizacje-carousel"}, type: {eq: "global"}}
    ){
      frontmatter{
        carousel_gallery{
          text_section{
            title
            title_visible
            subtitle
            text
            button_text
            button_url
            button_style
          }
          carousel_settings{
            slides_per_view
            transition_speed
            transition_delay
            object_fit
            rotate
            stretch
            depth
            scale
            slide_shadows
          }
          images{
            title
            title_visible
            description
            status
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`

export default IndexPage
