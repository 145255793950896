import * as React from "react"
// import PropTypes from "prop-types"
// import { graphql, useStaticQuery } from 'gatsby'
// import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage } from 'gatsby-plugin-image'

import Tile from "./tile"

const TileSection = ( {data, location} ) => {

    return (
      <div className={`${location}-tiles tile-section`}>
        {data.nodes.map((node) => (
            <Tile
              active={node.frontmatter.active}
              tileName={node.frontmatter.title}
              tileType={node.frontmatter.type}
              tileUrl={node.frontmatter.url}
              titleColor={node.frontmatter.title_color}
              titleBgColor={node.frontmatter.title_bg_color}
            >
              <GatsbyImage
                image={node.frontmatter.image.childImageSharp.gatsbyImageData}
                alt={node.frontmatter.title}
              />
            </Tile>
        ))}
      </div>
    )
  }

export default TileSection