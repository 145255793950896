import * as React from "react"

import Button from "./button"
import "./ctaButtonGrid.css"

const CtaButtonGrid = ({ className, children }) => {
    return (
        <div className="cta-button-grid">
            <Button btnStyle="color-dark" btnLink="/kontakt" btnText="CENY | TRANSPORT | DOSTĘPNOŚĆ" />
            <Button btnStyle="color-dark" btnLink="/kontakt" btnText="PYTANIA TECHNICZNE / DO PROJEKTU" />
            <Button btnStyle="color-dark" btnLink="/kontakt" btnText="REKLAMACJE | UMOWY" />
        </div>

    )
}
export default CtaButtonGrid