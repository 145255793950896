import * as React from "react"
// import PropTypes from "prop-types"

import CustomAniLink from "../components/customAniLink"
import styled from "styled-components"
import PropTypes from "prop-types"

import "./tile.css"

const Tile = ({ tileName, tileType, tileUrl, titleColor, titleBgColor, active, children }) => {

  const TileTitle = styled.div`
    color: ${titleColor || "rgba(255, 255, 255, 0.9)"};
    background-color: ${titleBgColor || "rgba(29, 29, 29, 0.9)"};
  `
  const state = active ? "active" : "disabled"

  return (
    <CustomAniLink className={`tile ${tileType} tile-${state}`} Link={`${active ? `${tileUrl}` : "#"}`} >
      {children}
      <TileTitle className="tile-title">{tileName}</TileTitle>
    </CustomAniLink>
  )
}

Tile.propTypes = {
  active: PropTypes.string,
};

Tile.defaultProps = {
  active: true,
};


export default Tile